import './rensol_logo.styles.scss';

function RensolLogo(props) {

    const defaultHeight = 400 ;
   const aspectRatio = 819/1600;
   const defaultWidth = defaultHeight / aspectRatio;

   return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 1600 819"
      style={{
         height: '45px',
         width: 'auto',
         display: 'inline-block',
         fillRule: 'evenodd',
         clipRule: 'evenodd',
         marginLeft: '12px'
      }}
      className="rensol-logo rensol-logo-header"
      {...props}
   >
         <g><path style={{ opacity: 0.988 }} fill="#bccf00" d="M 496.5,-0.5 C 509.5,-0.5 522.5,-0.5 535.5,-0.5C 615.45,11.6093 668.617,55.9426 695,132.5C 713.98,209.455 694.48,274.622 636.5,328C 608.924,350.949 577.257,364.949 541.5,370C 505.811,371.409 470.144,373.076 434.5,375C 397.773,383.731 377.273,406.898 373,444.5C 372.962,499.856 372.295,555.189 371,610.5C 363.88,653.945 340.047,684.112 299.5,701C 292.039,703.782 284.372,705.782 276.5,707C 246.193,708.135 215.859,708.635 185.5,708.5C 157.833,708.333 130.167,708.167 102.5,708C 43.4444,699.11 9.11111,665.277 -0.5,606.5C -0.5,551.167 -0.5,495.833 -0.5,440.5C 2.52729,405.945 17.5273,377.778 44.5,356C 61.1387,344.786 79.4721,338.119 99.5,336C 154.854,335.957 210.187,335.291 265.5,334C 299.165,328 320.332,308.5 329,275.5C 330.865,266.431 331.698,257.265 331.5,248C 331.014,227.145 330.348,206.312 329.5,185.5C 333.909,101.133 374.909,42.6329 452.5,10C 466.994,5.20828 481.661,1.70828 496.5,-0.5 Z M 505.5,36.5 C 567.962,35.3041 615.129,61.3041 647,114.5C 667.256,154.674 669.923,196.007 655,238.5C 636.039,284.794 602.539,314.961 554.5,329C 547.573,330.719 540.573,332.052 533.5,333C 502.847,334.303 472.18,335.303 441.5,336C 381.258,343.244 346.091,376.744 336,436.5C 334.719,491.816 334.052,547.149 334,602.5C 328.497,640.335 306.997,662.835 269.5,670C 241.691,671.126 213.857,671.626 186,671.5C 158.143,671.626 130.309,671.126 102.5,670C 63.3047,662.136 41.4713,638.302 37,598.5C 36.0696,545.485 36.4029,492.485 38,439.5C 44.0257,398.808 67.5257,376.641 108.5,373C 163.191,372.966 217.858,372.299 272.5,371C 327.894,359.939 359.727,326.439 368,270.5C 368.653,243.829 368.487,217.162 367.5,190.5C 368.654,122.647 399.987,74.8132 461.5,47C 475.856,41.576 490.523,38.076 505.5,36.5 Z" /></g>
         <g><path style={{ opacity: 0.979 }} fill="#747373" d="M 1564.5,376.5 C 1582.03,374.233 1592.87,381.566 1597,398.5C 1597.67,494.833 1597.67,591.167 1597,687.5C 1593.83,701.333 1585.17,708.667 1571,709.5C 1558.05,708.535 1549.72,701.868 1546,689.5C 1545.33,591.5 1545.33,493.5 1546,395.5C 1549.02,385.984 1555.18,379.651 1564.5,376.5 Z" /></g>
         <g><path style={{ opacity: 0.987 }} fill="#747373" d="M 566.5,452.5 C 598.953,449.369 626.453,459.369 649,482.5C 655.952,495.735 654.118,507.569 643.5,518C 635.819,523.335 627.485,524.669 618.5,522C 611.228,517.726 604.228,513.059 597.5,508C 571.532,499.406 552.366,507.239 540,531.5C 539.178,533.788 538.511,536.122 538,538.5C 537.977,589.537 537.311,640.537 536,691.5C 530.437,705.202 520.271,711.035 505.5,709C 495.442,706.609 488.942,700.443 486,690.5C 485.026,636.135 485.359,581.802 487,527.5C 497.875,485.789 524.375,460.789 566.5,452.5 Z" /></g>
         <g><path style={{ opacity: 0.984 }} fill="#747373" d="M 752.5,452.5 C 788.017,449.521 817.184,461.521 840,488.5C 848.992,500.82 854.992,514.487 858,529.5C 859.555,548.136 859.888,566.802 859,585.5C 857.049,596.119 850.882,602.952 840.5,606C 801.168,606.5 761.835,606.667 722.5,606.5C 721.359,636.684 735.693,653.684 765.5,657.5C 781.484,656.784 795.817,651.618 808.5,642C 819.591,636.077 830.591,636.077 841.5,642C 849.072,649.281 851.572,658.114 849,668.5C 845.734,675.849 840.901,682.015 834.5,687C 803.139,708.539 769.139,714.206 732.5,704C 697.44,689.27 676.94,663.104 671,625.5C 670.333,595.833 670.333,566.167 671,536.5C 678.81,489.526 705.977,461.526 752.5,452.5 Z M 756.5,504.5 C 790.615,503.614 807.615,520.281 807.5,554.5C 779.167,554.5 750.833,554.5 722.5,554.5C 720.974,528.836 732.308,512.169 756.5,504.5 Z" /></g>
         <g><path style={{ opacity: 0.985 }} fill="#747373" d="M 976.5,452.5 C 1024.61,449.509 1058.45,469.842 1078,513.5C 1080.43,520.316 1082.1,527.316 1083,534.5C 1083.67,586.167 1083.67,637.833 1083,689.5C 1077.16,705.973 1065.66,712.14 1048.5,708C 1039.29,704.13 1033.79,697.297 1032,687.5C 1031.67,638.167 1031.33,588.833 1031,539.5C 1023.96,513.814 1007.13,502.314 980.5,505C 964.105,509.728 953.272,520.228 948,536.5C 946.694,587.468 946.027,638.468 946,689.5C 940.351,706.245 928.851,712.412 911.5,708C 902.295,704.13 896.795,697.297 895,687.5C 894.333,638.167 894.333,588.833 895,539.5C 899.687,499.283 920.854,471.783 958.5,457C 964.581,455.29 970.581,453.79 976.5,452.5 Z" /></g>
         <g><path style={{ opacity: 0.983 }} fill="#747373" d="M 1194.5,452.5 C 1225.7,449.082 1252.87,457.748 1276,478.5C 1285.67,489.496 1287,501.496 1280,514.5C 1271.07,524.814 1260.24,527.314 1247.5,522C 1236.21,511.109 1222.71,504.942 1207,503.5C 1192.93,504.535 1181.59,510.535 1173,521.5C 1167.94,534.666 1171.44,544.833 1183.5,552C 1200.41,555.131 1217.41,557.797 1234.5,560C 1274.68,573.533 1291.84,601.366 1286,643.5C 1278.03,672.142 1260.19,691.975 1232.5,703C 1200.28,713.717 1169.62,710.383 1140.5,693C 1131.9,687.731 1125.4,680.564 1121,671.5C 1117.07,654.519 1123.24,643.019 1139.5,637C 1144.24,636.274 1148.91,636.607 1153.5,638C 1161.23,643.396 1169.23,648.396 1177.5,653C 1198.98,661.619 1217.48,657.452 1233,640.5C 1238.06,625.781 1233.56,614.948 1219.5,608C 1201.8,605.106 1184.14,602.106 1166.5,599C 1135.88,588.08 1119.88,566.413 1118.5,534C 1120.42,497.819 1138.08,472.819 1171.5,459C 1179.16,456.338 1186.83,454.172 1194.5,452.5 Z" /></g>
         <g><path style={{ opacity: 0.988 }} fill="#747373" d="M 1397.5,452.5 C 1446.82,449.23 1480.99,470.23 1500,515.5C 1501.81,521.056 1503.14,526.722 1504,532.5C 1506,564.833 1506,597.167 1504,629.5C 1494.36,675.14 1466.53,701.64 1420.5,709C 1380.17,711.253 1349.33,695.419 1328,661.5C 1322.33,651.171 1318.67,640.171 1317,628.5C 1316.33,596.833 1316.33,565.167 1317,533.5C 1326.01,488.654 1352.85,461.654 1397.5,452.5 Z M 1402.5,504.5 C 1431.3,502.806 1448.13,516.14 1453,544.5C 1453.67,568.5 1453.67,592.5 1453,616.5C 1449.79,641.545 1435.62,655.212 1410.5,657.5C 1388.79,655.734 1374.96,644.401 1369,623.5C 1368.03,593.785 1368.37,564.118 1370,534.5C 1375.62,518.71 1386.45,508.71 1402.5,504.5 Z" /></g>
         <g><path style={{ opacity: 0.946 }} fill="#bbcf00" d="M 1191.5,818.5 C 1187.5,818.5 1183.5,818.5 1179.5,818.5C 1176.97,817.492 1174.8,815.826 1173,813.5C 1172.51,815.134 1172.34,816.801 1172.5,818.5C 1170.5,818.5 1168.5,818.5 1166.5,818.5C 1165.36,797.21 1165.19,775.876 1166,754.5C 1167.73,752.398 1169.73,752.065 1172,753.5C 1172.33,761.5 1172.67,769.5 1173,777.5C 1178.27,772.755 1184.44,771.255 1191.5,773C 1200.83,777.475 1205.16,784.975 1204.5,795.5C 1205.51,806.428 1201.18,814.095 1191.5,818.5 Z M 1181.5,778.5 C 1190.98,777.508 1196.14,781.842 1197,791.5C 1197.73,796.669 1197.06,801.669 1195,806.5C 1191.72,812.109 1186.89,813.942 1180.5,812C 1175.33,807.827 1172.66,802.327 1172.5,795.5C 1172.67,788.157 1175.67,782.491 1181.5,778.5 Z" /></g>
         <g><path style={{ opacity: 0.866 }} fill="#bccf00" d="M 1219.5,818.5 C 1217.5,818.5 1215.5,818.5 1213.5,818.5C 1213.33,796.831 1213.5,775.164 1214,753.5C 1216.27,752.065 1218.27,752.398 1220,754.5C 1220.81,775.876 1220.64,797.21 1219.5,818.5 Z" /></g>
         <g><path style={{ opacity: 0.989 }} fill="#bbce00" d="M 1383.5,818.5 C 1381.5,818.5 1379.5,818.5 1377.5,818.5C 1376.37,797.864 1376.2,777.197 1377,756.5C 1377.05,754.115 1378.22,752.781 1380.5,752.5C 1382.43,752.59 1383.59,753.59 1384,755.5C 1384.8,776.531 1384.63,797.531 1383.5,818.5 Z" /></g>
         <g><path style={{ opacity: 0.911 }} fill="#bdd000" d="M 1463.5,756.5 C 1468.45,757.406 1469.95,760.073 1468,764.5C 1463.86,767.584 1461.03,766.584 1459.5,761.5C 1460.13,759.213 1461.46,757.546 1463.5,756.5 Z" /></g>
         <g><path style={{ opacity: 0.941 }} fill="#bcce00" d="M 1447.5,818.5 C 1445.83,818.5 1444.17,818.5 1442.5,818.5C 1441.54,805.216 1441.2,791.883 1441.5,778.5C 1436.33,779.508 1434.67,777.675 1436.5,773C 1438.13,772.506 1439.8,772.34 1441.5,772.5C 1441.33,768.818 1441.5,765.152 1442,761.5C 1443.01,758.552 1444.84,757.886 1447.5,759.5C 1448.49,763.783 1448.83,768.116 1448.5,772.5C 1450.53,772.338 1452.53,772.505 1454.5,773C 1455.83,774.667 1455.83,776.333 1454.5,778C 1452.53,778.495 1450.53,778.662 1448.5,778.5C 1448.81,791.913 1448.48,805.246 1447.5,818.5 Z" /></g>
         <g><path style={{ opacity: 0.885 }} fill="#bbce00" d="M 900.5,818.5 C 898.833,818.5 897.167,818.5 895.5,818.5C 894.367,803.878 894.2,789.211 895,774.5C 899.716,771.137 902.049,772.471 902,778.5C 904.633,773.268 908.799,771.435 914.5,773C 915.76,774.814 915.76,776.647 914.5,778.5C 909.489,779.58 905.656,782.246 903,786.5C 901.908,797.185 901.075,807.852 900.5,818.5 Z" /></g>
         <g><path style={{ opacity: 0.903 }} fill="#bccf00" d="M 947.5,818.5 C 942.833,818.5 938.167,818.5 933.5,818.5C 922.086,812.327 917.586,802.661 920,789.5C 923.002,775.579 931.502,770.079 945.5,773C 956.182,778.017 960.182,786.35 957.5,798C 947.506,798.5 937.506,798.666 927.5,798.5C 928.483,809.646 934.483,814.146 945.5,812C 948.009,810.579 950.343,808.912 952.5,807C 958.12,805.614 959.62,807.448 957,812.5C 954.157,815.163 950.991,817.163 947.5,818.5 Z M 935.5,778.5 C 939.544,777.958 943.211,778.791 946.5,781C 948.831,784.147 950.498,787.647 951.5,791.5C 943.528,792.498 935.528,792.831 927.5,792.5C 927.596,786.302 930.263,781.635 935.5,778.5 Z" /></g>
         <g><path style={{ opacity: 0.964 }} fill="#bccf00" d="M 1000.5,818.5 C 998.5,818.5 996.5,818.5 994.5,818.5C 993.732,807.199 993.232,795.866 993,784.5C 989.488,778.831 984.654,777.331 978.5,780C 976.274,781.227 974.774,783.061 974,785.5C 972.621,796.445 972.121,807.445 972.5,818.5C 970.5,818.5 968.5,818.5 966.5,818.5C 965.167,803.167 965.167,787.833 966.5,772.5C 970.831,771.451 972.997,773.118 973,777.5C 974.543,775.622 976.376,774.122 978.5,773C 988.36,770.351 995.527,773.518 1000,782.5C 1000.5,794.495 1000.67,806.495 1000.5,818.5 Z" /></g>
         <g><path style={{ opacity: 0.925 }} fill="#bccf00" d="M 1037.5,818.5 C 1032.83,818.5 1028.17,818.5 1023.5,818.5C 1016.36,815.516 1011.86,810.183 1010,802.5C 1007.62,791.658 1010.45,782.492 1018.5,775C 1039.46,768.635 1049.13,776.302 1047.5,798C 1037.17,798.333 1026.83,798.667 1016.5,799C 1021.34,813.841 1030,816.508 1042.5,807C 1044.13,806.506 1045.8,806.34 1047.5,806.5C 1047.66,808.527 1047.5,810.527 1047,812.5C 1044.16,815.163 1040.99,817.163 1037.5,818.5 Z M 1025.5,778.5 C 1034.97,778.306 1039.97,782.973 1040.5,792.5C 1032.83,792.5 1025.17,792.5 1017.5,792.5C 1017.6,786.302 1020.26,781.635 1025.5,778.5 Z" /></g>
         <g><path style={{ opacity: 0.929 }} fill="#bccf00" d="M 1098.5,818.5 C 1096.83,818.5 1095.17,818.5 1093.5,818.5C 1089.19,807.414 1085.36,796.08 1082,784.5C 1078.26,795.899 1074.42,807.232 1070.5,818.5C 1068.83,818.5 1067.17,818.5 1065.5,818.5C 1059.36,803.902 1054.52,788.902 1051,773.5C 1054.09,771.78 1056.42,772.447 1058,775.5C 1061.14,785.906 1064.47,796.239 1068,806.5C 1071.26,795.06 1075.09,783.893 1079.5,773C 1082.53,771.86 1084.69,772.694 1086,775.5C 1089.14,785.906 1092.47,796.239 1096,806.5C 1099.33,795.833 1102.67,785.167 1106,774.5C 1108.89,771.738 1111.39,772.071 1113.5,775.5C 1108.69,789.937 1103.69,804.27 1098.5,818.5 Z" /></g>
         <g><path style={{ opacity: 0.908 }} fill="#bccf00" d="M 1154.5,818.5 C 1152.83,818.5 1151.17,818.5 1149.5,818.5C 1148.85,816.914 1148.35,815.247 1148,813.5C 1146.27,815.402 1144.44,817.069 1142.5,818.5C 1138.17,818.5 1133.83,818.5 1129.5,818.5C 1123.88,816.376 1120.05,812.376 1118,806.5C 1114.06,794.338 1116.56,783.838 1125.5,775C 1130.57,772.576 1135.9,771.909 1141.5,773C 1144.1,773.883 1146.27,775.383 1148,777.5C 1148.64,772.313 1150.97,771.313 1155,774.5C 1155.8,789.211 1155.63,803.878 1154.5,818.5 Z M 1132.5,778.5 C 1139.79,777.559 1144.62,780.559 1147,787.5C 1149.24,795.294 1148.24,802.627 1144,809.5C 1135.25,815.014 1128.91,813.014 1125,803.5C 1122.1,793.389 1124.6,785.056 1132.5,778.5 Z" /></g>
         <g><path style={{ opacity: 0.92 }} fill="#bbcf00" d="M 1256.5,818.5 C 1251.83,818.5 1247.17,818.5 1242.5,818.5C 1231.39,812.605 1226.89,803.272 1229,790.5C 1231.79,776.356 1240.29,770.523 1254.5,773C 1265.46,777.922 1269.46,786.255 1266.5,798C 1256.51,798.5 1246.51,798.666 1236.5,798.5C 1237.5,809.666 1243.5,814.166 1254.5,812C 1257.01,810.579 1259.34,808.912 1261.5,807C 1267.12,805.614 1268.62,807.448 1266,812.5C 1263.16,815.163 1259.99,817.163 1256.5,818.5 Z M 1244.5,778.5 C 1253.96,777.612 1259.29,781.946 1260.5,791.5C 1252.53,792.498 1244.53,792.831 1236.5,792.5C 1236.6,786.302 1239.26,781.635 1244.5,778.5 Z" /></g>
         <g><path style={{ opacity: 0.914 }} fill="#bccf00" d="M 1313.5,818.5 C 1308.83,818.5 1304.17,818.5 1299.5,818.5C 1296.16,817.395 1293.32,815.395 1291,812.5C 1289.42,807.9 1290.92,806.066 1295.5,807C 1300.55,812.526 1306.55,813.859 1313.5,811C 1317.5,807.667 1317.5,804.333 1313.5,801C 1307.5,798.333 1301.5,795.667 1295.5,793C 1289.16,784.416 1290.82,777.749 1300.5,773C 1307.02,771.989 1313.36,772.655 1319.5,775C 1323.64,778.168 1323.47,780.668 1319,782.5C 1315.44,780.472 1311.6,779.138 1307.5,778.5C 1300.12,778.663 1298.12,781.829 1301.5,788C 1307.67,790.278 1313.67,792.945 1319.5,796C 1323.78,800.892 1324.61,806.392 1322,812.5C 1319.68,815.395 1316.84,817.395 1313.5,818.5 Z" /></g>
         <g><path style={{ opacity: 0.93 }} fill="#bccf00" d="M 1355.5,818.5 C 1351.17,818.5 1346.83,818.5 1342.5,818.5C 1335.69,815.842 1331.52,810.842 1330,803.5C 1328.06,793.795 1329.89,784.962 1335.5,777C 1351.05,767.797 1361.88,771.63 1368,788.5C 1369.49,797.005 1368.16,805.005 1364,812.5C 1361.33,814.902 1358.5,816.902 1355.5,818.5 Z M 1344.5,778.5 C 1355.68,778.349 1361.35,784.016 1361.5,795.5C 1361.34,802.327 1358.67,807.827 1353.5,812C 1345.46,814.231 1340.29,811.398 1338,803.5C 1335.04,793.684 1337.2,785.351 1344.5,778.5 Z" /></g>
         <g><path style={{ opacity: 0.911 }} fill="#bbcf00" d="M 1419.5,818.5 C 1414.83,818.5 1410.17,818.5 1405.5,818.5C 1400.18,816.682 1396.68,813.016 1395,807.5C 1394.33,796.5 1394.33,785.5 1395,774.5C 1396.67,772.302 1398.67,771.968 1401,773.5C 1401.07,784.905 1401.73,796.238 1403,807.5C 1404.8,811.235 1407.8,812.902 1412,812.5C 1415.73,812.799 1418.73,811.466 1421,808.5C 1422.27,796.904 1422.94,785.238 1423,773.5C 1425,772.167 1427,772.167 1429,773.5C 1429.67,785.167 1429.67,796.833 1429,808.5C 1426.98,813.02 1423.81,816.353 1419.5,818.5 Z" /></g>
         <g><path style={{ opacity: 0.863 }} fill="#bccf00" d="M 1467.5,818.5 C 1465.5,818.5 1463.5,818.5 1461.5,818.5C 1460.17,803.167 1460.17,787.833 1461.5,772.5C 1463.47,772.261 1465.3,772.594 1467,773.5C 1467.5,788.496 1467.67,803.496 1467.5,818.5 Z" /></g>
         <g><path style={{ opacity: 0.931 }} fill="#bccf00" d="M 1502.5,818.5 C 1498.17,818.5 1493.83,818.5 1489.5,818.5C 1483.88,816.376 1480.05,812.376 1478,806.5C 1474.06,794.338 1476.56,783.838 1485.5,775C 1500.37,768.856 1510.21,773.356 1515,788.5C 1517.26,798.547 1515.09,807.38 1508.5,815C 1506.57,816.459 1504.57,817.625 1502.5,818.5 Z M 1492.5,778.5 C 1499.85,777.585 1504.68,780.585 1507,787.5C 1509.45,795.33 1508.45,802.663 1504,809.5C 1495.25,815.014 1488.91,813.014 1485,803.5C 1481.92,793.323 1484.42,784.989 1492.5,778.5 Z" /></g>
         <g><path style={{ opacity: 0.927 }} fill="#bccf00" d="M 1558.5,818.5 C 1556.5,818.5 1554.5,818.5 1552.5,818.5C 1552.91,806.771 1552.41,795.104 1551,783.5C 1543.87,776.46 1537.53,777.126 1532,785.5C 1531.79,796.546 1531.29,807.546 1530.5,818.5C 1528.5,818.5 1526.5,818.5 1524.5,818.5C 1524.33,803.496 1524.5,788.496 1525,773.5C 1528.42,771.867 1530.75,772.867 1532,776.5C 1533.32,774.513 1535.15,773.346 1537.5,773C 1548.64,770.645 1555.81,774.812 1559,785.5C 1559.79,796.546 1559.62,807.546 1558.5,818.5 Z" /></g>
         <g><path style={{ opacity: 0.928 }} fill="#bbcf00" d="M 1599.5,800.5 C 1599.5,803.833 1599.5,807.167 1599.5,810.5C 1597.12,813.881 1594.12,816.547 1590.5,818.5C 1585.5,818.5 1580.5,818.5 1575.5,818.5C 1572.47,816.891 1569.64,814.891 1567,812.5C 1565.42,807.9 1566.92,806.066 1571.5,807C 1576.55,812.526 1582.55,813.859 1589.5,811C 1593.37,807.582 1593.37,804.249 1589.5,801C 1582.77,798.218 1576.27,795.051 1570,791.5C 1565.99,783.193 1568.16,777.026 1576.5,773C 1581.17,772.333 1585.83,772.333 1590.5,773C 1595.96,773.947 1598.62,777.114 1598.5,782.5C 1596.47,782.662 1594.47,782.495 1592.5,782C 1587.91,778.301 1582.91,777.635 1577.5,780C 1574.92,782.544 1574.92,785.044 1577.5,787.5C 1582.46,789.764 1587.46,791.931 1592.5,794C 1595.36,795.694 1597.69,797.861 1599.5,800.5 Z" /></g>
      </svg>

   );
}

export default RensolLogo;
