import './energreen_logo.styles.scss';

function EnergreenLogo(props) {
  const defaultHeight = 40; // Set a smaller default height
  const aspectRatio = 386.908/101.513;
  const defaultWidth = defaultHeight * aspectRatio;
  
  return (
    <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width={defaultWidth} 
    height={defaultHeight}
    viewBox="0 0 386.908 101.513"
    style={{
      display: 'block',
      height: '100%',
      width: 'auto',
      maxHeight: '40px'
    }}
    {...props}
  >
  <g id="Logo-energreen-map" transform="translate(4127 1253)">
    <path id="Path_887" data-name="Path 887" d="M102.161,52.57c0-9.618,9.019-11.6,15.269-11.6,6.268,0,15.269,1.985,15.269,11.584l-.131,1.852H109.2c1.292,3.705,6.4,4.4,9.787,4.4a36.475,36.475,0,0,0,11.077-2.02v5.8a43.023,43.023,0,0,1-11.471,1.553c-7.99,0-16.429-2.246-16.429-11.564m23.465-2.1c0-1.31-1.367-4.173-8.2-4.173s-8.177,2.863-8.177,4.173Z" transform="translate(-4130.918 -1254.571)" fill="#172b17"/>
    <path id="Path_888" data-name="Path 888" d="M138.883,41.8h6.361l.374,3.162a16.217,16.217,0,0,1,10.892-4.135c6.624,0,12.65,2.34,12.65,11.957V63.024h-6.737V52.788c0-5.146-4.266-6.624-7.185-6.624-5.221,0-9.618,2.582-9.618,6.251V63.024h-6.735Z" transform="translate(-4132.326 -1254.566)" fill="#172b17"/>
    <path id="Path_889" data-name="Path 889" d="M175.527,52.57c0-9.618,9.018-11.6,15.269-11.6,6.268,0,15.269,1.985,15.269,11.584l-.131,1.852H182.562c1.292,3.705,6.4,4.4,9.787,4.4a36.475,36.475,0,0,0,11.077-2.02v5.8a43.023,43.023,0,0,1-11.471,1.553c-7.99,0-16.429-2.246-16.429-11.564m23.465-2.1c0-1.31-1.367-4.173-8.2-4.173s-8.177,2.863-8.177,4.173Z" transform="translate(-4133.732 -1254.571)" fill="#172b17"/>
    <path id="Path_890" data-name="Path 890" d="M211.529,41.817h6.362l.374,3.444c2.432-3.48,6.736-4.566,10.4-4.566a20.569,20.569,0,0,1,4.042.412v5.744a21.669,21.669,0,0,0-4.491-.6c-7.56,0-9.955,3.762-9.955,9.955v6.811h-6.736Z" transform="translate(-4135.112 -1254.561)" fill="#172b17"/>
    <path id="Path_891" data-name="Path 891" d="M239.932,71.261V65.5a27.873,27.873,0,0,0,9.862,1.889c5.988,0,9.992-1.8,9.992-6.606v-.242c-2.9,2.657-7.148,3.517-10.442,3.517-5.595,0-14.034-2.488-14.034-11.582s8.439-11.583,14.034-11.583c3.294,0,7.541.861,10.442,3.518l.375-2.489h6.361V61.455c0,6.156-4.958,11.639-16.84,11.639a29.992,29.992,0,0,1-9.75-1.833m10.985-12.538c4.247,0,8.869-2,8.869-6.25s-4.623-6.25-8.869-6.25-8.869,1.571-8.869,6.25,4.622,6.25,8.869,6.25" transform="translate(-4136.024 -1254.568)" fill="#172b17"/>
    <path id="Path_892" data-name="Path 892" d="M273.608,41.817h6.362l.374,3.444c2.432-3.48,6.736-4.566,10.4-4.566a20.569,20.569,0,0,1,4.042.412v5.744a21.67,21.67,0,0,0-4.491-.6c-7.559,0-9.955,3.762-9.955,9.955v6.811h-6.736Z" transform="translate(-4137.494 -1254.561)" fill="#172b17"/>
    <path id="Path_893" data-name="Path 893" d="M297.74,52.57c0-9.618,9.018-11.6,15.269-11.6,6.268,0,15.269,1.985,15.269,11.584l-.131,1.852H304.775c1.292,3.705,6.4,4.4,9.787,4.4a36.475,36.475,0,0,0,11.077-2.02v5.8a43.023,43.023,0,0,1-11.471,1.553c-7.99,0-16.429-2.246-16.429-11.564m23.465-2.1c0-1.31-1.366-4.173-8.2-4.173s-8.177,2.863-8.177,4.173Z" transform="translate(-4138.419 -1254.571)" fill="#172b17"/>
    <path id="Path_894" data-name="Path 894" d="M334.131,52.57c0-9.618,9.019-11.6,15.269-11.6,6.268,0,15.269,1.985,15.269,11.584l-.131,1.852H341.166c1.292,3.705,6.4,4.4,9.787,4.4a36.475,36.475,0,0,0,11.077-2.02v5.8a43.023,43.023,0,0,1-11.471,1.553c-7.99,0-16.429-2.246-16.429-11.564m23.465-2.1c0-1.31-1.367-4.173-8.2-4.173s-8.177,2.863-8.177,4.173Z" transform="translate(-4139.814 -1254.571)" fill="#172b17"/>
    <path id="Path_895" data-name="Path 895" d="M370.855,41.8h6.361l.374,3.162a16.217,16.217,0,0,1,10.892-4.135c6.624,0,12.65,2.34,12.65,11.957V63.024h-6.737V52.788c0-5.146-4.266-6.624-7.185-6.624-5.221,0-9.618,2.582-9.618,6.251V63.024h-6.735Z" transform="translate(-4141.224 -1254.566)" fill="#172b17"/>
    <path id="Path_896" data-name="Path 896" d="M48.311,24.438A28.1,28.1,0,0,0,24.17,48.847,28.1,28.1,0,0,0,0,24.438,28.13,28.13,0,0,0,24.17,0,28.145,28.145,0,0,0,48.311,24.438" transform="translate(-4127 -1253)" fill="#a0d52d"/>
    <path id="Path_897" data-name="Path 897" d="M101.677,24.438A28.1,28.1,0,0,0,77.536,48.847a28.1,28.1,0,0,0-24.17-24.409A28.13,28.13,0,0,0,77.536,0a28.145,28.145,0,0,0,24.141,24.438" transform="translate(-4129.046 -1253)" fill="#a0d52d"/>
    <path id="Path_898" data-name="Path 898" d="M48.311,79.2A28.1,28.1,0,0,0,24.17,103.613,28.1,28.1,0,0,0,0,79.2,28.13,28.13,0,0,0,24.17,54.766,28.145,28.145,0,0,0,48.311,79.2" transform="translate(-4127 -1255.1)" fill="#a0d52d"/>
    <path id="Path_899" data-name="Path 899" d="M101.677,79.2a28.1,28.1,0,0,0-24.141,24.409A28.1,28.1,0,0,0,53.366,79.2a28.13,28.13,0,0,0,24.17-24.438A28.145,28.145,0,0,0,101.677,79.2" transform="translate(-4129.046 -1255.1)" fill="#a0d52d"/>
    <path id="Path_900" data-name="Path 900" d="M274.918,81.1V79.633a2.966,2.966,0,0,0,1.241.338c.734,0,1.047-.489,1.378-1.061L274.48,73.7h1.843l2.117,3.754,2.121-3.754h1.868l-3.763,6.419a2.56,2.56,0,0,1-2.287,1.263,4.451,4.451,0,0,1-1.462-.277" transform="translate(-4137.527 -1255.826)" fill="#a0d52d"/>
    <path id="Path_901" data-name="Path 901" d="M283.212,76.286c0-2.366,2.338-2.854,3.962-2.854s3.959.489,3.959,2.854-2.336,2.858-3.959,2.858-3.962-.493-3.962-2.858m3.962,1.54c1.1,0,2.3-.387,2.3-1.54s-1.2-1.541-2.3-1.541-2.3.388-2.3,1.541,1.2,1.54,2.3,1.54" transform="translate(-4137.862 -1255.816)" fill="#a0d52d"/>
    <path id="Path_902" data-name="Path 902" d="M292.746,76.2V73.681h1.661V76.2a1.618,1.618,0,0,0,1.77,1.632c1.286,0,2.37-.636,2.37-1.54V73.681h1.66v5.228h-1.568l-.091-.779a3.992,3.992,0,0,1-2.683,1.019c-1.632,0-3.118-.577-3.118-2.946" transform="translate(-4138.228 -1255.826)" fill="#a0d52d"/>
    <path id="Path_903" data-name="Path 903" d="M301.959,73.675h1.567l.093.848a3.045,3.045,0,0,1,2.563-1.125,5.063,5.063,0,0,1,1,.1v1.416a5.319,5.319,0,0,0-1.106-.148c-1.864,0-2.453.927-2.453,2.453V78.9h-1.66Z" transform="translate(-4138.581 -1255.815)" fill="#a0d52d"/>
    <path id="Path_904" data-name="Path 904" d="M314.089,73.7h1.659v.613a3.889,3.889,0,0,1,2.572-.866c1.379,0,3.458.613,3.458,2.853s-2.079,2.854-3.458,2.854a3.885,3.885,0,0,1-2.572-.867v2.141h-1.659Zm3.845,4.14c1.046,0,2.185-.388,2.185-1.541s-1.139-1.54-2.185-1.54-2.186.493-2.186,1.54,1.139,1.541,2.186,1.541" transform="translate(-4139.046 -1255.817)" fill="#a0d52d"/>
    <path id="Path_905" data-name="Path 905" d="M322.927,76.286c0-2.366,2.338-2.854,3.961-2.854s3.96.489,3.96,2.854-2.337,2.858-3.96,2.858-3.961-.493-3.961-2.858m3.961,1.54c1.1,0,2.3-.387,2.3-1.54s-1.2-1.541-2.3-1.541-2.3.388-2.3,1.541,1.2,1.54,2.3,1.54" transform="translate(-4139.385 -1255.816)" fill="#a0d52d"/>
    <path id="Path_906" data-name="Path 906" d="M331.64,73.7H333.4l1.512,3.753L336.43,73.7h1.577l1.513,3.753,1.517-3.753H342.8l-2.19,5.215h-2.08l-1.31-3.117L335.91,78.91h-2.079Z" transform="translate(-4139.719 -1255.826)" fill="#a0d52d"/>
    <path id="Path_907" data-name="Path 907" d="M343.733,76.323c0-2.369,2.222-2.858,3.762-2.858s3.762.489,3.762,2.853l-.032.457h-5.759c.318.913,1.577,1.084,2.412,1.084a8.988,8.988,0,0,0,2.729-.5V78.79a10.572,10.572,0,0,1-2.826.383c-1.968,0-4.047-.552-4.047-2.849m5.781-.515c0-.323-.337-1.029-2.019-1.029s-2.015.706-2.015,1.029Z" transform="translate(-4140.183 -1255.818)" fill="#a0d52d"/>
    <path id="Path_908" data-name="Path 908" d="M352.6,73.675h1.567l.093.848a3.045,3.045,0,0,1,2.563-1.125,5.063,5.063,0,0,1,1,.1v1.416a5.32,5.32,0,0,0-1.106-.148c-1.864,0-2.453.927-2.453,2.453V78.9H352.6Z" transform="translate(-4140.523 -1255.815)" fill="#a0d52d"/>
    <path id="Path_909" data-name="Path 909" d="M359.335,72.219H361V74.4a3.987,3.987,0,0,1,2.683-1.018c1.632,0,3.117.575,3.117,2.946v2.522h-1.66V76.331a1.617,1.617,0,0,0-1.77-1.632c-1.287,0-2.37.637-2.37,1.54v2.615h-1.661Z" transform="translate(-4140.781 -1255.77)" fill="#a0d52d"/>
    <path id="Path_910" data-name="Path 910" d="M368.2,76.286c0-2.366,2.338-2.854,3.961-2.854s3.96.489,3.96,2.854-2.337,2.858-3.96,2.858-3.961-.493-3.961-2.858m3.961,1.54c1.1,0,2.3-.387,2.3-1.54s-1.2-1.541-2.3-1.541-2.3.388-2.3,1.541,1.2,1.54,2.3,1.54" transform="translate(-4141.121 -1255.816)" fill="#a0d52d"/>
    <path id="Path_911" data-name="Path 911" d="M377.732,76.2V73.681h1.661V76.2a1.618,1.618,0,0,0,1.77,1.632c1.286,0,2.37-.636,2.37-1.54V73.681h1.66v5.228h-1.568l-.091-.779a3.992,3.992,0,0,1-2.683,1.019c-1.632,0-3.118-.577-3.118-2.946" transform="translate(-4141.487 -1255.826)" fill="#a0d52d"/>
    <path id="Path_912" data-name="Path 912" d="M386.785,78.407V76.932a6.342,6.342,0,0,0,3.158.9c1.222,0,1.96-.171,1.96-.535,0-.231-.291-.268-1.392-.378l-1.185-.12c-1.194-.12-2.592-.3-2.592-1.535,0-1.157,1.222-1.821,3.241-1.821a8.226,8.226,0,0,1,2.822.41v1.337a7.194,7.194,0,0,0-2.748-.479c-.507,0-1.655.014-1.655.508,0,.23.249.289,1.254.391l1.37.138c1.585.161,2.544.378,2.544,1.507v.01c0,1.6-1.955,1.881-3.5,1.881a7.287,7.287,0,0,1-3.273-.738" transform="translate(-4141.833 -1255.817)" fill="#a0d52d"/>
    <path id="Path_913" data-name="Path 913" d="M394.514,76.323c0-2.369,2.222-2.858,3.762-2.858s3.762.489,3.762,2.853l-.032.457h-5.759c.318.913,1.577,1.084,2.412,1.084a8.987,8.987,0,0,0,2.729-.5V78.79a10.572,10.572,0,0,1-2.826.383c-1.969,0-4.048-.552-4.048-2.849m5.781-.515c0-.323-.337-1.029-2.019-1.029s-2.015.706-2.015,1.029Z" transform="translate(-4142.13 -1255.818)" fill="#a0d52d"/>
    <rect id="Rectangle_972" data-name="Rectangle 972" width="386.908" height="101.512" transform="translate(-4127 -1253)" fill="none"/>
  </g>
</svg>



  );
}

export default EnergreenLogo;
